<template>
  <div class="subscriptions">
    <div class="subscriptions_title">
      {{ $t("SubscriptionsTitle") }}
    </div>
    <div class="content" @scroll="nextPage">
      <div class="is_initialized" v-if="isInitialized">
        <div class="empty" v-if="netWorkError">
          <img
            @error="imageError"
            :src="`${$store.state.assetsUrl}/imgs/subscription/network_error.webp`"
            alt="empty"
          />
          <div class="no_have_subscription">
            {{ $t("LiveHome_Empty_ItemTitle") }}
          </div>
          <div class="reload_button" @click="reload">
            {{ $t("LiveHome_Retry_ItemTitle") }}
          </div>
        </div>
        <div class="empty" v-else-if="subscriptionsList.length == 0">
          <img
            @error="imageError"
            :src="`${$store.state.assetsUrl}/imgs/subscription/empty.webp`"
            alt="empty"
          />
          <div class="no_have_subscription">{{ $t("NoHaveSubscription") }}</div>
        </div>
        <div class="subscriptions_list" v-else>
          <div class="list_wrap">
            <div
              class="subscriptions_list_item"
              v-for="(item, ind) in subscriptionsList"
              :key="ind"
            >
              <div class="subscriptions_info">
                <div class="info_detail">
                  <div class="info_title">
                    {{ $t("LitelyPro") }} ({{ item.value }}
                    {{ item | getTimeUnit }})
                  </div>
                  <div class="start_time">{{ $t("StartDate") }}</div>
                  <div class="start_time">{{ getStart(item.created_at) }}</div>
                </div>
                <img
                  @error="imageError"
                  class="logo"
                  alt="logo"
                  :src="`${$store.state.assetsUrl}/imgs/logo1214.webp`"
                />
              </div>
              <div
                v-if="item.auto_renewing"
                @click="cancelSubscription(item)"
                class="cancel_button"
              >
                {{ $t("Setting_VipCancelSubscription_ItemTitle") }}
              </div>
              <div class="canceled" v-else>{{ $t("Canceled") }}</div>
            </div>
            <div class="more_tips">
              <loading
                v-if="!finished"
                :size="24"
                :borderSize="2"
                :borderColor="'#E5E5E5'"
              />
              <div v-else>{{ $t("NoMore") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loading">
        <loading :size="48" :borderSize="4" :borderColor="'#E5E5E5'" />
      </div>
      <cover-loading :showCover="cancelLoading"></cover-loading>
      <van-popup v-model="showAlert" round>
        <div class="alert_content">
          <div class="alert_content_title">
            {{ $t("CancelSubscriptionTitle") }}
          </div>
          <div class="choose_button" @click="goCancel">
            {{ $t("CancelSubscription") }}
          </div>
          <div class="choose_button" @click="showAlert = false">
            {{ $t("No") }}
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showCancelResult" round>
        <div class="result_alert_content">
          <img
            @error="imageError"
            :src="
              success
                ? `${$store.state.assetsUrl}/imgs/user_center/success.webp`
                : `${$store.state.assetsUrl}/imgs/user_center/fail.webp`
            "
            alt="result"
          />
          <div class="describe">
            {{
              success ? $t("SubscriptionCanceled") : $t("CancellationFailed")
            }}
            <div v-if="!success" class="fail_text">{{ $t("CancelError") }}</div>
          </div>
          <div class="button" @click="showCancelResult = false">
            {{ $t("ok") }}
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import CoverLoading from "@/components/cover_loading.vue";
import Loading from "@/components/loading.vue";
import { userSubscription, cancelSubscriptions } from "@/api/index.js";
import { Toast } from "vant";
import { common } from "../assets/utils/common";
import { commonMixin } from "../assets/mixin/common";
export default {
  name: "subscriptionList",
  components: { CoverLoading, Loading },
  mixins: [commonMixin],
  data() {
    return {
      isInitialized: false,
      subscriptionsList: [],
      cancelLoading: false,
      moreValue: 0,
      size: 5,
      showAlert: false,
      cancelItem: {},
      showCancelResult: false,
      success: false,
      failReason: "",
      loading: false,
      finished: false,
      netWorkError: false,
    };
  },
  filters: {
    getTimeUnit(info) {
      if (info.value_type == 0) {
        // 当类型为0时value是月份
        return info.value > 1 ? "months" : "month";
      } else if (info.value_type == 1) {
        // 当类型为0时value是天数
        return info.value > 1 ? "days" : "day";
      }
      return "";
    },
  },
  created() {
    this.getData();
  },
  methods: {
    nextPage(e) {
      if (this.loading || this.finished) return;
      let target = e.target;
      if (target.scrollTop + target.clientHeight == target.scrollHeight) {
        this.getData();
      }
    },
    goCancel() {
      if (this.cancelLoading) return;
      this.showAlert = false;
      this.cancelLoading = true;
      cancelSubscriptions({
        subscription_id: this.cancelItem.id,
      })
        .then((res) => {
          this.cancelLoading = false;
          if (res.errorCode) {
            this.success = false;
          } else {
            this.success = true;
            this.subscriptionsList.forEach((item) => {
              if (item.id == this.cancelItem.id) {
                item.auto_renewing = false;
              }
            });
          }
          this.showCancelResult = true;
        })
        .catch(() => {
          this.cancelLoading = false;
          Toast(this.$t("NetworkErrorToast"));
        });
    },
    cancelSubscription(item) {
      if (item.store == 3) {
        this.showAlert = true;
        this.cancelItem = item;
      } else {
        Toast(this.$t("GoPhoneToast"));
      }
    },
    getStart(time) {
      var date = new Date(time); // 参数需要毫秒数，所以这里将秒数乘于 1000
      let Y = date.getFullYear();
      let M = common.monthList[date.getMonth()];
      let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return M + " " + D + "," + " " + Y;
    },
    reload() {
      this.netWorkError = false;
      this.isInitialized = false;
      this.moreValue = 0;
      this.getData();
    },
    getData() {
      this.loading = true;
      userSubscription({
        size: this.size,
        more_value: this.moreValue,
      })
        .then((res) => {
          this.netWorkError = false;
          this.loading = false;
          if (res.errorCode) {
            Toast(this.$t("NetworkError"));
            return;
          }
          if (!this.isInitialized) {
            this.isInitialized = true;
          }
          this.subscriptionsList = this.subscriptionsList.concat(
            res.lists ?? []
          );
          this.moreValue = res.more_value;
          if (res.more_value == 0) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.netWorkError = true;
          this.isInitialized = true;
          Toast(this.$t("NetworkError"));
        });
    },
  },
};
</script>

<style scoped lang="less">
.subscriptions {
  max-width: 765px;
  margin: 0 auto;
  padding-top: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;

  .alert_content {
    padding: 30px 25px 0;
    width: 335px;
    box-sizing: border-box;
    text-align: center;

    .alert_content_title {
      font-size: 20px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #444444;
      line-height: 27px;
      margin-bottom: 35px;
    }

    .choose_button {
      width: 100%;
      font-size: 18px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #666666;
      line-height: 25px;
      padding: 16px 0;
      border-top: 1px solid rgba(239, 239, 239, 1);
      cursor: pointer;
    }
  }

  .result_alert_content {
    padding: 30px 25px 26px;
    width: 335px;
    box-sizing: border-box;
    text-align: center;

    img {
      width: 64px;
      height: 64px;
      margin-bottom: 32px;
    }

    .describe {
      font-size: 20px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #444444;
      line-height: 27px;
    }

    .fail_text {
      font-size: 12px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: #e85345;
      line-height: 16px;
      margin: 10px;
    }

    .button {
      height: 55px;
      background: #fd7c61;
      border-radius: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #ffffff;
      line-height: 25px;
      cursor: pointer;
      margin-top: 20px;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
  }

  .subscriptions_title {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #444444;
    line-height: 24px;
  }

  .empty {
    padding-top: 96px;
    text-align: center;

    img {
      width: 140px;
      height: 140px;
    }

    .no_have_subscription {
      font-size: 15px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: #777777;
      line-height: 20px;
      margin-top: 18px;
    }
  }

  .subscriptions_list {
    margin-top: 24px;

    .list_wrap {
      padding: 0 20px;
      margin-bottom: 24px;
    }

    .subscriptions_list_title {
      font-size: 14px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: #999999;
      line-height: 19px;
      margin-left: 4px;
    }

    .subscriptions_list_item {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(151, 151, 151, 0.18);
      border-radius: 10px;
      margin: 12px auto 10px;
      padding: 15px;

      .subscriptions_info {
        display: flex;
        justify-content: space-between;

        .info_detail {
          .info_title {
            font-size: 16px;
            font-family: "AvenirNext";
            font-weight: 600;
            color: rgba(51, 51, 51, 0.8);
            line-height: 22px;
          }

          .info_month {
            font-size: 14px;
            font-family: "AvenirNext";
            font-weight: 500;
            color: rgba(51, 51, 51, 0.6);
            line-height: 19px;
            margin-top: 10px;
          }

          .start_time {
            font-size: 14px;
            font-family: "AvenirNext";
            font-weight: 500;
            color: rgba(51, 51, 51, 0.6);
            line-height: 19px;
            margin-top: 4px;
          }
        }

        .logo {
          width: 40px;
          height: 40px;
          margin-left: 20px;
        }
      }

      .cancel_button {
        margin-top: 24px;
        height: 44px;
        background: rgba(255, 184, 178, 0.3);
        border-radius: 10px;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        font-family: "AvenirNext";
        font-weight: 600;
        color: #e85345;
        cursor: pointer;
      }

      .canceled {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-family: "AvenirNext";
        font-weight: 600;
        color: #bbb;
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .more_tips {
    text-align: center;
    margin-top: 40px;
    font-size: 12px;
    color: #999;
  }

  .reload_button {
    width: 100px;
    height: 40px;
    background: #fd7c61;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    font-size: 16px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    cursor: pointer;
  }

  img {
    pointer-events: none;
  }
}
</style>
